<template>

  <b-row>

    <!-- Left -->
    <b-col
      cols="12"
      lg="9"
    >

      <b-alert
        :show="userData.role === 'admin' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        Please see the following list of requested changes.
        <ul
          class="d-flex flex-column flex-wrap mt-50"
          style="max-height: 300px;"
        >
          <li
            v-for="(value, index) in formUpdateChanges"
            :key="index"
          >
            {{ value }}
          </li>
        </ul>
        <hr>
        <div class="text-right">
          <b-button
            variant="danger"
            class="mr-1"
            @click="denyFormUpdate"
          >
            Deny
          </b-button>
          <b-button
            variant="secondary"
            @click="approveFormUpdate"
          >
            Approve
          </b-button>
        </div>
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 1"
        variant="warning"
        style="padding: 0.71rem 1rem; border: 1px solid transparent;"
      >
        You still have pending profile update requests. Please wait for the Admin to review them before making any other changes.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 2"
        variant="primary"
        dismissible
        fade
        style="padding: 0.71rem 1rem; cursor: pointer; border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        The HR/Admin has approved the changes you made.
      </b-alert>

      <b-alert
        :show="userData.role === 'agent' && formUpdateStatus === 3"
        variant="danger"
        dismissible
        fade
        style="border: 1px solid transparent;"
        @dismissed="dismissFormUpdate"
      >
        <div
          style="padding: 0.71rem 1rem; cursor: pointer;"
          @click="dismissFormUpdate"
        >
          The HR/Admin has denied the changes you made.
        </div>
      </b-alert>

      <b-overlay
        :show="userData.role === 'agent' && formUpdateStatus > 0"
        spinner-type="false"
        opacity="0.25"
        blur
        rounded
      >

        <!-- Card -->
        <b-card>

          <b-row class="hr-form">

            <b-col
              cols="12"
            >

              <b-card-title>
                Employment Checklist
              </b-card-title>

              <p>The following requirements are necessary for Circle of Care (COC) to process your application for immediate employment.  Please submit these requirements together with your completed HR Packet.</p>
              <hr>

              <!-- Form -->
              <validation-observer ref="simpleRules">
                <b-form ref="formFieldsRef">

                  <b-form-input
                    v-model="form.form"
                    type="text"
                    class="hidden"
                  />

                  <b-form-input
                    v-model="form.id"
                    type="text"
                    class="hidden"
                  />

                  <input
                    v-model="form.cid"
                    type="hidden"
                  >

                  <h4>Primary Requirements</h4>
                  <b-row>
                    <!-- Input Name of Applicant  -->
                    <b-col
                      cols="8"
                    >
                      <b-form-group
                        label="* Name of Applicant"
                        label-for="applicant-name"
                        class="mb-2"
                      >
                        <label
                          class="sr-only"
                          for="applicant-name"
                        >
                          * Name of Applicant
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Name of Applicant"
                          rules="required"
                        >
                          <b-form-input
                            id="applicant-name"
                            v-model="applicantName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Name of Applicant"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Primary Requirements"
                        rules="required"
                      >
                        <b-form-group>
                          <b-form-checkbox-group
                            ref="primaryReqData"
                            v-model="form.primaryReqData"
                            :options="['Personnel Record (HCS 501)', 'Criminal Record Statement (LIC 508) / Live Scan / Finger Print – DOJ and FBI Clearance', 'Proof of Clearance for Affiliated HCAs', 'Statement Acknowledging Requirement to Report Suspected Abuse (SOC 341A)', 'Home Care Aide Training Log (HCS 500)', 'TB Clearance / X-Ray Result', 'Home Care Aide Registration (LIC 9182 / LIC 9183)', 'HCA / CAN / HHA License / RN License / LVN License', 'Driver’s License / Govt. issued ID /Social Security Card / TIN Number', 'Harassment Training and Certificate', 'COVID-19 Vaccination']"
                            :state="errors.length > 0 ? false : null"
                            stacked
                            title="Primary Requirements"
                          />

                          <b-form-radio-group
                            ref="primaryReqSubData"
                            v-model="form.primaryReqSubData"
                            :options="['1st Dose', '2nd Dose', '3rd Dose']"
                            class="indent"
                            title="Primary Requirements"
                          />
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <br>
                  <h4>Additional Requirements, if available</h4>
                  <b-row>
                    <!-- <b-col
                      cols="12"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Additional Requirements"
                        rules="required"
                      >
                        <b-form-group>
                          <b-form-checkbox-group
                            ref="additionalReqData"
                            v-model="form.additionalReqData"
                            :options="['CPR / First Aid Certificate Card', 'Physical Examination Result', 'Proof of Auto Insurance if driving', 'Bank Information (Void check or Direct Deposit Certificate)']"
                            :state="errors.length > 0 ? false : null"
                            stacked
                            title="Additional Requirements"
                          />
                        </b-form-group>

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-col> -->
                    <b-col
                      cols="12"
                    >
                      <b-form-group>
                        <b-form-checkbox-group
                          ref="additionalReqData"
                          v-model="form.additionalReqData"
                          :options="['CPR / First Aid Certificate Card', 'Physical Examination Result', 'Proof of Auto Insurance if driving', 'Bank Information (Void check or Direct Deposit Certificate)']"
                          stacked
                          title="Additional Requirements"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row
                    class="mt-2"
                  >
                    <!-- Input Received By  -->
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* Received by"
                        label-for="received-by"
                      >
                        <label
                          class="sr-only"
                          for="received-by"
                        >
                          * Received by
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Received by"
                          rules="required"
                        >
                          <b-form-input
                            id="received-by"
                            ref="receivedBy"
                            v-model="form.receivedBy"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Received by"
                            title="Received by"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Input Received Date  -->
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Date"
                          label-for="received-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="received-date"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="received-date"
                            ref="receivedDate"
                            v-model="form.receivedDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="receivedDate"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <!-- Input Interviewed By  -->
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* Interviewed by"
                        label-for="interviewed-by"
                      >
                        <label
                          class="sr-only"
                          for="interviewed-by"
                        >
                          * Received by
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Interviewed by"
                          rules="required"
                        >
                          <b-form-input
                            id="interviewed-by"
                            ref="interviewedBy"
                            v-model="form.interviewedBy"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Interviewed by"
                            title="Interviewed by"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Test"
                        label-for="test"
                      >
                        <label
                          class="sr-only"
                          for="test"
                        >
                          * Test
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Test"
                          rules="required"
                        >
                          <b-form-checkbox-group
                            ref="interviewedByData"
                            v-model="form.interviewedByData"
                            :options="['Reference', 'Background', 'Harassment', 'X-Ray', 'TB Test', 'COVID Test', 'Name Badge', 'Orientation']"
                            :state="errors.length > 0 ? false : null"
                            title="Test"
                          />

                          <small class="text-danger"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* All Forms Signed:"
                        label-for="all-forms-signed"
                      >
                        <label
                          class="sr-only"
                          for="all-forms-signed"
                        >
                          * All Forms Signed
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="All Forms Signed"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="allFormsSignedData"
                            v-model="form.allFormsSignedData"
                            :options="['Yes', 'No']"
                            :state="errors.length > 0 ? false : null"
                            title="All Forms Signed"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                      <b-form-group
                        label="* Status:"
                        label-for="status"
                      >
                        <label
                          class="sr-only"
                          for="status"
                        >
                          * Status
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Status"
                          rules="required"
                        >
                          <b-form-radio-group
                            ref="statusData"
                            v-model="form.statusData"
                            :options="['Stand-by', 'Hired']"
                            :state="errors.length > 0 ? false : null"
                            title="Status"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                    >
                      <b-form-group
                        label="Note"
                        label-for="note"
                      >
                        <label
                          class="sr-only"
                          for="note"
                        >
                          Note
                        </label>
                        <b-form-input
                          id="note"
                          ref="note"
                          v-model="form.note"
                          placeholder="Note"
                          title="Note"
                        />
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Assigned to"
                        label-for="assigned-to"
                      >
                        <label
                          class="sr-only"
                          for="assigned-to"
                        >
                          * Assigned to
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Assigned to"
                          rules="required"
                        >
                          <b-form-input
                            id="assigned-to"
                            ref="assignedTo"
                            v-model="form.assignedTo"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Assigned to"
                            title="Assigned to"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="6"
                    >
                      <b-form-group
                        label="* Assigned Location"
                        label-for="assigned-location"
                      >
                        <label
                          class="sr-only"
                          for="assigned-location"
                        >
                          * Assigned Location
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Assigned Location"
                          rules="required"
                        >
                          <b-form-checkbox-group
                            ref="assignedToData"
                            v-model="form.assignedToData"
                            :options="['Facility', 'Private', 'County']"
                            :state="errors.length > 0 ? false : null"
                            title="Assigned Location"
                          />
                          <small class="text-danger"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Start Date"
                        rules="required"
                      >
                        <b-form-group
                          label="* Start Date"
                          label-for="start-date"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="start-date"
                          >
                            * Date
                          </label>
                          <flat-pickr
                            id="start-date"
                            ref="startDate"
                            v-model="form.startDate"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Start Date"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* Address"
                        label-for="address"
                      >
                        <label
                          class="sr-only"
                          for="address"
                        >
                          * Address
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Address"
                          rules="required"
                        >
                          <b-form-input
                            id="address"
                            ref="Address"
                            v-model="address"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Address"
                            title="Address"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Schedule"
                        rules="required"
                      >
                        <b-form-group
                          label="* Schedule"
                          label-for="schedule"
                          :state="errors.length > 0 ? false : null"
                        >
                          <label
                            class="sr-only"
                            for="schedule"
                          >
                            * Schedule
                          </label>
                          <flat-pickr
                            id="schedule"
                            ref="schedule"
                            v-model="form.schedule"
                            class="form-control"
                            :config="flatPickrConfig"
                            placeholder="Choose a date"
                            title="Schedule"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* Contact Name"
                        label-for="contact-name"
                      >
                        <label
                          class="sr-only"
                          for="contact-name"
                        >
                          * Contact Name
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Contact Name"
                          rules="required"
                        >
                          <b-form-input
                            id="contact-name"
                            v-model="contactName"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Contact Name"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="Email"
                        label-for="email"
                      >
                        <label
                          class="sr-only"
                          for="email"
                        >
                          Email
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Email"
                          rules="email"
                        >
                          <b-form-input
                            id="email"
                            v-model="emailAddress"
                            :state="errors.length > 0 ? false : null"
                            type="email"
                            placeholder="Email"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* Phone Number"
                        label-for="phone-number"
                      >
                        <label
                          class="sr-only"
                          for="phone-number"
                        >
                          * Phone Number
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Phone Number"
                          rules="required"
                        >
                          <b-input-group>
                            <b-input-group-prepend is-text>
                              US (+1)
                            </b-input-group-prepend>
                            <b-form-input
                              id="phone-number"
                              v-model="phoneNumber"
                              v-mask="['(###) ###-####']"
                              :state="errors.length > 0 ? false : null"
                              type="tel"
                              placeholder="Phone Number"
                            />
                          </b-input-group>
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                  <b-row
                    align-v="center"
                  >
                    <b-col
                      cols="12"
                      lg="4"
                    >
                      <b-form-group
                        label="* Client Feedback 24 to 48 hours after 1st assignment"
                        label-for="client-feedback"
                      >
                        <label
                          class="sr-only"
                          for="client-feedback"
                        >
                          * Client Feedback
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Client Feedback"
                          rules="required"
                        >
                          <b-form-input
                            id="client-feedback"
                            ref="clientFeedback"
                            v-model="form.clientFeedback"
                            :state="errors.length > 0 ? false : null"
                            placeholder="Client Feedback"
                            title="Client Feedback"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      cols="12"
                      lg="8"
                    >
                      <b-form-group
                        label="* Data Log"
                        label-for="data-log"
                      >
                        <label
                          class="sr-only"
                          for="data-log"
                        >
                          * Data Log
                        </label>
                        <validation-provider
                          #default="{ errors }"
                          name="Data Log"
                          rules="required"
                        >
                          <b-form-checkbox-group
                            ref="dataLogData"
                            v-model="form.dataLogData"
                            :options="['COC Platform', 'Telephony', 'E-mail', 'Payroll', 'Direct Pay', 'Zelle']"
                            :state="errors.length > 0 ? false : null"
                            title="Data Log"
                          />
                          <small class="text-danger"><br>{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </validation-observer>

            </b-col>
          </b-row>

        </b-card>

      </b-overlay>
    </b-col>

    <!-- Right -->
    <b-col
      cols="12"
      lg="3"
    >

      <!-- Action Buttons -->
      <b-card>

        <!-- Button Preview -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="gradient-main"
          class="mb-75 btn-print-preview"
          block
          @click="$store.dispatch('app-hr/preview')"
        >
          Print Preview
        </b-button>

        <!-- Button Save -->
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="secondary"
          block
          :disabled="(userData.role === 'agent' && formUpdateStatus > 0) ? true : isNotSaved"
          @click.prevent="validationForm"
        >
          Save
        </b-button>

        <!-- Button Cancel -->
        <b-button
          v-if="action !== 'preview'"
          variant="outline-dark"
          class="mb-75"
          :to="{ name: (userData.role === 'agent' ? 'caregiverLanding' : 'hr') }"
          block
        >
          Cancel
        </b-button>

        <div class="d-flex">
          <!-- Button Previous -->
          <b-button
            variant="outline-dark"
            class="flex-fill mr-25"
            :to="{ name: 'apps-hr-form', params: { action: action }, query: { id: id } }"
          >
            Previous
          </b-button>

          <!-- Button Next -->
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="secondary"
            class="flex-fill ml-25"
            :to="{ name: 'apps-hr-form2', params: { action: action }, query: { id: id } }"
          >
            Next
          </b-button>
        </div>

        <!-- Spacer -->
        <hr class="mb-50">

        <label for="forms">Form Selection</label>
        <v-select
          id="forms"
          v-model="forms"
          :reduce="(option) => option.name"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          :options="formsOptions"
          :clearable="false"
          :disabled="!id"
          class="hr-forms-select"
          @input="$router.push({ name: forms, params: { action: action }, query: { id: id } })"
        >
          <template #selected-option="{ label }">
            <span class="text-truncate overflow-hidden">
              {{ label }}
            </span>
          </template>
        </v-select>
      </b-card>

    </b-col>
  </b-row>

</template>

<script>
import SockJS from 'sockjs-client'
import Ripple from 'vue-ripple-directive'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BOverlay, BAlert, BCard, BCardTitle, BRow, BCol, BForm, BFormGroup, BFormInput, BButton, BFormCheckboxGroup, BInputGroupPrepend, BInputGroup, BFormRadioGroup, VBToggle,
} from 'bootstrap-vue'
import Swal from 'sweetalert2'
import { required, email } from '@validations'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import { mask } from 'vue-the-mask'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'

import { getUserData } from '@/auth/utils'

import hrStoreModule from '../hrStoreModule'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,

    BOverlay,
    BAlert,
    BCard,
    BCardTitle,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckboxGroup,
    BInputGroupPrepend,
    BInputGroup,
    BButton,
    BFormRadioGroup,

    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
    mask,
  },
  setup() {
    const HR_APP_STORE_MODULE_NAME = 'app-hr'

    // Register module
    if (!store.hasModule(HR_APP_STORE_MODULE_NAME)) store.registerModule(HR_APP_STORE_MODULE_NAME, hrStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HR_APP_STORE_MODULE_NAME)) store.unregisterModule(HR_APP_STORE_MODULE_NAME)
    })

    const form = ref({})

    const applicantName = ref('')
    const address = ref('')
    const contactName = ref('')
    const emailAddress = ref('')
    const phoneNumber = ref('')

    const input = ref(true)

    const formUpdate = ref({})
    const formUpdateStatus = ref(0)

    const formCopy = ref({})

    if (router.currentRoute.query.id) {
      store.dispatch('app-hr/fetchForm', { id: router.currentRoute.query.id })
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          form.value = {}

          form.value.cid = router.currentRoute.query.id

          // eslint-disable-next-line
          applicantName.value = jsonObjectLiterals.data.firstName + ' ' + jsonObjectLiterals.data.lastName
          address.value = jsonObjectLiterals.data.address
          contactName.value = jsonObjectLiterals.data.emergencyContactName
          emailAddress.value = jsonObjectLiterals.data.emergencyContactEmailAddress
          phoneNumber.value = jsonObjectLiterals.data.emergencyContactNumber

          if (router.currentRoute.params.action === 'edit' || (router.currentRoute.params.action === 'add' && router.currentRoute.query.id)) {
            store.dispatch('app-hr/fetchForm1', { cid: router.currentRoute.query.id })
              .then(response1 => {
                // eslint-disable-next-line
                const jsonObjectLiterals = response1.data.response.data

                if (jsonObjectLiterals.data.cid) {
                  form.value = jsonObjectLiterals.data
                }

                if (form.value.id) {
                  form.value.form = 'folder3_form1'

                  formCopy.value = JSON.parse(JSON.stringify(form.value))

                  store.dispatch('app-hr/fetchFormUpdate', { form: form.value.form, form_id: form.value.id })
                    .then(response2 => {
                      // eslint-disable-next-line
                      const jsonObjectLiterals = response2.data.response.data

                      // console.log(jsonObjectLiterals.data)

                      if (jsonObjectLiterals.data.status) {
                        formUpdate.value = jsonObjectLiterals.data

                        formUpdateStatus.value = formUpdate.value.status
                      }
                    })
                    .catch(error => {
                      console.error(error)
                    })
                }
              })
              .catch(error => {
                console.error(error)
              })
          }
        })
        .catch(error => {
          console.error(error)
        })
    }

    const userData = ref(getUserData())

    const formsOptions = [
      { label: 'Basic Information', name: 'apps-hr-form' },
      { label: 'Employment Checklist', name: 'apps-hr-form1' },
      { label: 'Employment Application', name: 'apps-hr-form2' },
      { label: 'Employment Skills List', name: 'apps-hr-form3' },
      { label: 'Work Experiences Checklist', name: 'apps-hr-form4' },
      { label: 'Employment Eligibility Verification', name: 'apps-hr-form5' },
      { label: 'Form W-4', name: 'apps-hr-form6' },
      { label: 'Non-Compete and Non-Solicitation Agreement', name: 'apps-hr-form7' },
      { label: 'Employment Verification Fax', name: 'apps-hr-form8' },
      { label: 'Ref Inquiry Form', name: 'apps-hr-form9' },
      { label: 'Request for Live Scan Service - Community Care Licensing', name: 'apps-hr-form10' },
      { label: 'New Hire Orientation Acknowledgment Form', name: 'apps-hr-form11' },
      { label: 'Employee Tuberculin Skin Test (TST) and Evaluation', name: 'apps-hr-form12' },
      { label: 'Home Care Organization Inspection Checklist', name: 'apps-hr-form13' },
      { label: 'Personnel Record (Admin)', name: 'apps-hr-form14a' },
      { label: 'Personnel Record', name: 'apps-hr-form14b' },
      { label: 'Applicant for Home Care Aide Registration', name: 'apps-hr-form14c' },
      { label: 'Criminal Record Statement', name: 'apps-hr-form15' },
      { label: 'Statement Acknowledging Requirement to Report Suspected Abuse of Dependent Adults and Elders', name: 'apps-hr-form16' },
      { label: 'Registered Home Care Aide Training Log A', name: 'apps-hr-form17a' },
      { label: 'Registered Home Care Aide Training Log B', name: 'apps-hr-form17b' },
      { label: 'Workplace Safety', name: 'apps-hr-form18' },
      { label: 'Current Knowledge', name: 'apps-hr-form19' },
      { label: 'Fact Sheet (Home Care Services - April 2017)', name: 'apps-hr-form20' },
      { label: 'Acknowledgement Form - Fact Sheet (Home Care Services)', name: 'apps-hr-form21' },
      { label: 'Training Completion Certificate', name: 'apps-hr-form22' },
    ]

    const flatPickrConfig = {
      dateFormat: 'Y-m-d',
    }

    const sock = new SockJS('https://socket.motivit.com:443/echo')
    const sockId = 'coc-n01'

    return {
      form,

      applicantName,
      address,
      contactName,
      emailAddress,
      phoneNumber,

      input,

      formUpdate,
      formUpdateStatus,

      formCopy,

      userData,

      formsOptions,

      flatPickrConfig,

      sock,
      sockId,
    }
  },
  data() {
    return {
      action: this.$route.params.action,
      id: this.$route.query.id,

      isNotSaved: false,

      forms: this.$route.name,

      formUpdateChanges: [],

      required,
      email,
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'formUpdate.data': function () {
      Object.entries(this.formUpdate.data).forEach(([key, val]) => {
        if (JSON.stringify(val) !== JSON.stringify(this.formCopy[key]) && (Number(val) !== Number(this.formCopy[key]) || String(val) !== String(this.formCopy[key]))) {
          this.formUpdateChanges.push(this.$refs[key].$attrs.title)
        }
      })
    },
  },
  created() {
    this.sock.onopen = () => {
      // console.log('Connection established.')
    }

    this.sock.onmessage = event => {
      const data = JSON.parse(event.data)
      if (data.id === this.sockId) {
        this.getSockMsg(data.msg)
      }
    }

    this.sock.onclose = () => {
      // console.warn('Connection closed.')
    }
  },
  methods: {
    getSockMsg(sockMsg) {
      if (sockMsg === true) {
        // Do something
      }
    },
    sendSockMsg(sockMsg) {
      this.sock.send(JSON.stringify({ id: this.sockId, msg: sockMsg }))
    },
    requestFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/requestFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 1

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    denyFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/denyFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          if (jsonObjectLiterals.data.id) {
            self.form = jsonObjectLiterals.data
          }

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    approveFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/approveFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          self.sendSockMsg(true)

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    dismissFormUpdate() {
      const self = this

      store
        .dispatch('app-hr/dismissFormUpdate', this.formCopy)
        .then(response => {
          // eslint-disable-next-line
          const jsonObjectLiterals = response.data.response.data

          self.formUpdateStatus = 0

          // console.log(jsonObjectLiterals.data)
        })
        .catch(error => {
          console.error(error)
        })
    },
    validationForm() {
      const self = this

      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let msg = 'Do you want to save the changes?'
          if (this.action === 'add') msg = 'Do you want to add these data?'
          Swal.fire({
            icon: 'warning',
            title: 'Are you sure?',
            text: msg,
            confirmButtonText: 'Proceed',
            showCancelButton: true,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.isNotSaved = true

              store
                .dispatch(this.action === 'add' ? 'app-hr/addForm1' : 'app-hr/updateForm1', this.form)
                .then(response => {
                  // eslint-disable-next-line
                  const jsonObjectLiterals = response.data.response.data

                  this.form.id = jsonObjectLiterals.id

                  if (this.userData.role === 'agent' && this.action === 'edit' && jsonObjectLiterals.count > 0) {
                    self.requestFormUpdate()
                  }

                  Swal.fire({
                    icon: 'success',
                    title: 'Saved!',
                    text: 'Saved succesfully.',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .catch(error => {
                  console.error(error)

                  Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    showConfirmButton: false,
                    timer: 1500,
                  })
                })
                .finally(() => {
                  self.isNotSaved = false
                })
            }
          })
        } else {
          Swal.fire({
            icon: 'error',
            // title: 'Are you sure?',
            // eslint-disable-next-line
            text: 'Please fill-up all of the required fields.',
            confirmButtonText: 'OK',
            showCancelButton: false,
            reverseButtons: true,
            customClass: {
              confirmButton: 'btn btn-secondary',
              cancelButton: 'btn btn-outline-dark mr-1',
            },
            buttonsStyling: false,
            returnFocus: false,
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style>
  /* [dir] .custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before, [dir] .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
    background-color: #fff;
  }

  [dir] .custom-checkbox .custom-control-input:checked:indeterminate ~ .custom-control-label::before {
    border-color: #569099;
    background-color: #569099;
  }

  [dir] .custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
    background-image: none !important;
  } */

  .custom-radioClass {
    margin-top: 6px;
  }

  .indent {
    margin-left: 25px;
  }
</style>

<style lang="scss" scoped>
.hr-forms-select {
  width: 100%;
  min-width: 182px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 135px;
  }
}

@media (max-width: 1199.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 112px;
    }
  }
}

@media (max-width: 991.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 598px;
    }
  }
}

@media (max-width: 767.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 406px;
    }
  }
}

@media (max-width: 575.98px) {
  .hr-forms-select {
    min-width: 100%;

    ::v-deep .vs__selected {
      width: 352px;
    }
  }
}
</style>
